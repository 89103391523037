import React, { useState } from 'react';
import ReactSlider from 'react-slider';
import styles from './style.scss';
import { usePricePackageStep } from '../home-care-price-root/hooks/PricePackageProvider';
var PriceRangeSlider = function (props) {
    var servicePackage = usePricePackageStep().servicePackage;
    var _a = useState(0), value = _a[0], setValue = _a[1];
    return (<div className='mt-4'>
      <div className='d-flex gap-2 '>
        <div className="d-flex gap-2 mt-4 flex-grow-1 align-items-center ">
          <div className={styles.markPoint}>Self</div>
          <div className={styles.root}>
            <ReactSlider className={'root'} min={props.min} max={props.max} step={0.1} thumbClassName={"thumb ".concat(styles.thumb)} trackClassName={"track ".concat(styles.track)} thumbActiveClassName={styles.tracActive} onAfterChange={function (value) { return setValue(value); }} renderThumb={function (props, state) {
            var _a;
            return <div {...props}>
                <div className={styles.value}>
                $ {state.valueNow.toLocaleString('AU', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} / $ {((((_a = servicePackage.level) === null || _a === void 0 ? void 0 : _a.monthly_cost) || 0) - state.value).toLocaleString('AU', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                </div>
              </div>;
        }}/>
          </div>
          <div className={styles.markPoint}>Gov</div>
        </div>
        {props.onChange && <button role="button" className="btn btn-sm btn-orange mt-2" disabled={value == 0} onClick={function () {
                var _a;
                (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, value);
            }}><span>Confirm</span></button>}
      </div>
    </div>);
};
export default PriceRangeSlider;
