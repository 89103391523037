import { createContext, useContext } from "react";
//from https://medium.com/@rivoltafilippo/typing-react-context-to-avoid-an-undefined-default-value-2c7c5a7d5947
export var createGenericContext = function () {
    // Create a context with a generic parameter or undefined
    var genericContext = createContext(undefined);
    // Check if the value provided to the context is defined or throw an error
    var useGenericContext = function () {
        var contextIsDefined = useContext(genericContext);
        if (!contextIsDefined) {
            throw new Error("useGenericContext must be used within a Provider");
        }
        return contextIsDefined;
    };
    return [useGenericContext, genericContext.Provider];
};
