import React from 'react';
import styles from './style.scss';
import { usePricePackageStep } from '../hooks/PricePackageProvider';
var PricePackageStepNav = function () {
    var steps = [
        {
            label: 'Step 1',
            description: 'Which level package have you been assigned?'
        },
        {
            label: 'Step 2',
            description: 'How much is your Income Tested Care Fee?'
        },
        {
            label: 'Step 3',
            description: 'Everyone pays a 15% Package Management fee'
        },
        {
            label: 'Step 4',
            description: 'Now you choose which Benetas Care Management you need'
        },
        {
            label: 'Step 5',
            description: 'You have $X to spend on our services as part of your care plan'
        },
    ];
    var step = usePricePackageStep().step;
    return (<div>
      <div className='d-flex g-2 py-4 justify-content-between position-relative align-items-center'>
        {steps.map(function (pStep, index) { return (<div className={"ff-dax ".concat(styles.card, " ").concat((step >= index) ? styles.active : '')} key={index}>
              {pStep.label}
              {/* <h5>{pStep.description}</h5> */}
            </div>); })}
        <div className={styles.stepBarTrack}>
          <div className={styles.stepBar} style={{ width: "".concat(100 * step / (steps.length - 1), "%") }}>
          </div>
        </div>
      </div>
    </div>);
};
export default PricePackageStepNav;
