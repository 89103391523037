var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo, useState } from 'react';
import { createGenericContext } from '../../utils/createGenericContext';
var _a = createGenericContext(), usePricePackageStep = _a[0], PricePackageStepProviderContextProvider = _a[1];
var PricePackageStepProvider = function (_a) {
    var children = _a.children;
    var _b = useState(0), step = _b[0], setStep = _b[1];
    var _c = useState({
        serviceFee: 0,
        systemFee: 0.15
    }), servicePackage = _c[0], setServicePackage = _c[1];
    var price = useMemo(function () {
        var _a;
        return (((_a = servicePackage.level) === null || _a === void 0 ? void 0 : _a.monthly_cost) || 0) * (1 - servicePackage.serviceFee - servicePackage.systemFee);
    }, [servicePackage]);
    var serviceHours = useMemo(function () {
        var _a, _b;
        if (servicePackage.serviceFee == 0.17)
            return (_a = servicePackage.level) === null || _a === void 0 ? void 0 : _a.service_hours[0];
        return (_b = servicePackage.level) === null || _b === void 0 ? void 0 : _b.service_hours[1];
    }, [servicePackage]);
    var goNextStep = function () {
        setStep(function (prev) { return prev + 1; });
    };
    var resetStep = function () {
        setStep(0);
    };
    var selectLevel = function (level) {
        setServicePackage(__assign(__assign({}, servicePackage), { level: level }));
        goNextStep();
    };
    var selectServiceFee = function (serviceFee) {
        setServicePackage(__assign(__assign({}, servicePackage), { serviceFee: serviceFee }));
        goNextStep();
    };
    return (<PricePackageStepProviderContextProvider value={{
            step: step,
            price: price,
            serviceHours: serviceHours,
            servicePackage: servicePackage,
            setStep: setStep,
            goNextStep: goNextStep,
            resetStep: resetStep,
            selectLevel: selectLevel,
            selectServiceFee: selectServiceFee
        }}>
      {children}
    </PricePackageStepProviderContextProvider>);
};
export { usePricePackageStep, PricePackageStepProvider };
