var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'flatpickr/dist/themes/material_green.css';
import Flatpickr from 'react-flatpickr';
import classes from './form.scss';
import axios from 'axios';
var CustomInput = function (_a) {
    var defaultValue = _a.defaultValue, inputRef = _a.inputRef, props = __rest(_a, ["defaultValue", "inputRef"]);
    return <input className="form-control" defaultValue={defaultValue} ref={inputRef} {...props}/>;
};
var occupations = [
    {
        title: 'Benetas or MRH client',
        description: 'Benetas or MRH client',
    },
    {
        title: 'Relative/friend',
        description: 'Relative/friend',
    },
    {
        title: 'Benetas or MRH employee',
        description: 'Benetas or MRH employee'
    },
    {
        title: 'Advocate',
        description: 'Advocate'
    },
    {
        title: 'Volunteer',
        description: 'Volunteer'
    },
    {
        title: 'Contractor',
        description: 'Contractor'
    },
    {
        title: 'Member of the public',
        description: 'Member of the public'
    },
    {
        title: 'Benetas or MRH employee completing on behalf of a client',
        description: 'Benetas or MRH employee completing on behalf of a client'
    },
    {
        title: 'Other',
        description: ''
    }
];
var FeedbackForm = function () {
    var _a = useState(false), isSubmited = _a[0], setSubmited = _a[1];
    var formik = useFormik({
        initialValues: {
            feedback_type: '',
            ceo_compliment: '',
            translation_assistance: '',
            advocacy_information: '',
            anonymous: '',
            behalf_of_client: '',
            client_name: '',
            date: '',
            residence_service: '',
            reporter_name: '',
            reporter_occupation: '',
            other_occupation_detail: '',
            need_reseponse: '',
            tele_phone: '',
            email: '',
            feedback: '',
            personal_idea: ''
        },
        // validateOnBlur:false,
        validateOnChange: false,
        validationSchema: Yup.object({
            feedback_type: Yup.string().required('Please pick feedback type.'),
            ceo_compliment: Yup.string().required('This field is required.'),
            translation_assistance: Yup.string().required('This field is required.'),
            advocacy_information: Yup.string().required('This field is required.'),
            anonymous: Yup.string().required('This field is required.'),
            behalf_of_client: Yup.string(),
            client_name: Yup.string(),
            date: Yup.string().when(['behalf_of_client'], function (_a, schema) {
                var behalf_of_client = _a[0];
                if (behalf_of_client == 'yes')
                    return schema.required();
                return schema;
            }),
            residence_service: Yup.string(),
            reporter_name: Yup.string(),
            reporter_occupation: Yup.string(),
            other_occupation_detail: Yup.string().when(['anonymous', 'reporter_occupation'], function (_a, schema) {
                var anonymous = _a[0], reporter_occupation = _a[1];
                if (anonymous == 'no' && reporter_occupation == 'other')
                    return schema.required('This field is required.');
                return schema;
            }),
            need_reseponse: Yup.string(),
            tele_phone: Yup.string(),
            email: Yup.string(),
            feedback: Yup.string().required(),
            personal_idea: Yup.string()
        }),
        onSubmit: function (values, _a) {
            var setSubmitting = _a.setSubmitting;
            return __awaiter(void 0, void 0, void 0, function () {
                var error_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, axios.post('/contact-us/feedback', values)];
                        case 1:
                            _b.sent();
                            setSubmited(true);
                            return [3 /*break*/, 3];
                        case 2:
                            error_1 = _b.sent();
                            setSubmited(false);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
    });
    var onChangeCheckBox = function (e) {
        formik.setFieldValue(e.target.name, e.target.value.toLowerCase());
    };
    return (<div>
      <h3 className="mb-5 text-center">Compliments, Complaints and Suggestions Form</h3>

      {!formik.isValid &&
            <div className="alert alert-danger" role="alert">
          Sorry, there was an error submitting the form. Please try again.
        </div>}
      {isSubmited &&
            <div className="alert alert-success" role="alert">
          Message submission successful!
        </div>}

      {!isSubmited && <>

        <div className='row mb-4'>
          <div className='form-group col-sm-12 col-12'>
            <div className={classes.requiredLabel}>Please provide your feedback</div>
            <div className="form-check mt-2">
              <input className="form-check-input" type="radio" name="feedback_type" id="compliment" value="compliment" onChange={onChangeCheckBox}/>
              <label className="form-check-label" htmlFor="compliment">Compliment</label>
            </div>

            <div className="form-check mt-2">
              <input className="form-check-input" type="radio" name="feedback_type" id="suggestion" value="suggestion" onChange={onChangeCheckBox}/>
              <label className="form-check-label" htmlFor="suggestion"> Suggestion </label>
            </div>

            <div className="form-check mt-2">
              <input className="form-check-input" type="radio" name="feedback_type" id="complaint" value={'complaint'} onChange={onChangeCheckBox}/>
              <label className="form-check-label" htmlFor="complaint"> Complaint </label>
            </div>

            {formik.errors.feedback_type && <div className={classes.errorHelperText}>This field is required</div>}

          </div>
        </div>

        <div className='row mb-4'>
          <div className='form-group col-sm-12 col-12'>
            <div className={classes.requiredLabel}>Is this a complaint specifically about the CEO or Board?</div>
            <div className="form-check mt-2">
              <input className="form-check-input" type="radio" name="ceo_compliment" id="ceo_compliment_yes" value="yes" onChange={onChangeCheckBox}/>
              <label className="form-check-label" htmlFor="ceo_compliment_yes"> Yes </label>
            </div>

            <div className="form-check mt-2">
              <input className="form-check-input" type="radio" name="ceo_compliment" id="ceo_compliment_no" value="no" onChange={onChangeCheckBox}/>
              <label className="form-check-label" htmlFor="ceo_compliment_no"> No </label>
            </div>

            {formik.errors.ceo_compliment && <div className={classes.errorHelperText}>This field is required</div>}

          </div>
        </div>

        <div className='row mb-4'>
          <div className='form-group col-sm-12 col-12'>
            <div className={classes.requiredLabel}>I require translation assistance?</div>

            <div className="form-check mt-2">
              <input className="form-check-input" type="radio" name="translation_assistance" id="translation_assistance_yes" value="yes" onChange={onChangeCheckBox}/>
              <label className="form-check-label" htmlFor="translation_assistance_yes"> Yes </label>
            </div>

            <div className="form-check mt-2">
              <input className="form-check-input" type="radio" name="translation_assistance" id="translation_assistance_no" value="no" onChange={onChangeCheckBox}/>
              <label className="form-check-label" htmlFor="translation_assistance_no"> No </label>
            </div>
            {formik.errors.translation_assistance && <div className={classes.errorHelperText}>This field is required</div>}

          </div>
        </div>

        <div className='row mb-4'>
          <div className='form-group col-sm-12 col-12'>
            <div className={classes.requiredLabel}>I require advocacy information or support?</div>

            <div className="form-check mt-2">
              <input className="form-check-input" type="radio" name="advocacy_information" id="advocacy_information_yes" value="yes" onChange={onChangeCheckBox}/>
              <label className="form-check-label" htmlFor="advocacy_information_yes"> Yes </label>
            </div>

            <div className="form-check mt-2">
              <input className="form-check-input" type="radio" name="advocacy_information" id="advocacy_information_no" value="no" onChange={onChangeCheckBox}/>
              <label className="form-check-label" htmlFor="advocacy_information_no"> No </label>
            </div>

            {formik.errors.advocacy_information && <div className={classes.errorHelperText}>This field is required</div>}
          </div>
        </div>

        <div className='row mb-4'>
          <div className='form-group col-sm-12 col-12'>

            <div className={classes.requiredLabel}>I want to be anonymous?</div>

            <div className="form-check mt-2">
              <input className="form-check-input" type="radio" name="anonymous" id="anonymous_yes" value="yes" onChange={onChangeCheckBox}/>
              <label className="form-check-label" htmlFor="anonymous_yes"> Yes </label>
            </div>

            <div className="form-check mt-2">
              <input className="form-check-input" type="radio" name="anonymous" id="anonymous_no" value="no" onChange={onChangeCheckBox}/>
              <label className="form-check-label" htmlFor="anonymous_no"> No </label>
            </div>
            {formik.errors.anonymous && <div className={classes.errorHelperText}>This field is required</div>}

          </div>
        </div>

      
        {formik.values.anonymous == 'no' &&
                <>
              <h1>Your details</h1>
              <div className='row mb-4'>
                <div className='form-group col-sm-12 col-12'>
                  <div className={classes.requiredLabel}>I am completing this form on behalf of a Benetas client?</div>
                  <div className="form-check mt-2">
                    <input className="form-check-input" type="radio" name="behalf_of_client" id="behalf_of_client_yes" value='yes' onChange={onChangeCheckBox}/>
                    <label className="form-check-label" htmlFor="behalf_of_client_yes"> Yes </label>
                  </div>
                  <div className="form-check mt-2">
                    <input className="form-check-input" type="radio" name="behalf_of_client" id="behalf_of_client_no" value="no" onChange={onChangeCheckBox}/>
                    <label className="form-check-label" htmlFor="behalf_of_client_no"> No </label>
                  </div>
                  {formik.errors.behalf_of_client && <div className={classes.errorHelperText}>This field is required</div>}
                </div>
              </div>

              {formik.values.behalf_of_client == 'yes' && (<div className='row mb-4'>
                    <div className='form-group col-sm-12 col-12'>
                      <div className="mb-3">
                        <label htmlFor="client_name" className="form-label">Name of Benetas client being represented (if applicable)</label>
                        <input className="form-control" id="client_name"/>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="date-picker" className={"form-label ".concat(classes.requiredLabel)}>Date</label>
                        <Flatpickr name='date' value={formik.values.date} options={{ minDate: '2017-01-01' }} onChange={function (_a, stringVal) {
                            var date = _a[0];
                            console.log({ date: date, stringVal: stringVal });
                            formik.setFieldValue('date', date);
                        }} render={function (_a, ref) {
                            var defaultValue = _a.defaultValue, props = __rest(_a, ["defaultValue"]);
                            return <CustomInput id="date-picker" defaultValue={defaultValue} inputRef={ref} {...props}/>;
                        }}/>
                        {formik.errors.date && <div className={classes.errorHelperText}>This field is required</div>}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="residence_service" className="form-label">Residence/Service:</label>
                        <input className="form-control" id="residence_service"/>
                      </div>
                    </div>
                  </div>)}

              <div className='row mb-4'>
                <div className='form-group col-sm-12 col-12'>
                  <div className="mb-3">
                    <label htmlFor="reporter_name" className="form-label">Name</label>
                    <input className={"form-control ".concat(formik.errors.reporter_name ? classes.invalideFormControl : '')} id="reporter_name" name='reporter_name' value={formik.values.reporter_name} onChange={formik.handleChange}/>
                    {formik.errors.reporter_name && <div className={classes.errorHelperText}>{formik.errors.reporter_name.toString()}</div>}
                  </div>

                  <div>I am a</div>
                  {occupations.map(function (occupation, key) { return (<div className="form-check mt-2" key={key}>
                      <input className="form-check-input" type="radio" name="reporter_occupation" id={occupation.title} value={occupation.title} onChange={onChangeCheckBox}/>
                      <label className="form-check-label" htmlFor={occupation.title}>{occupation.title}</label>
                    </div>); })}
                </div>
              </div>

              <div className='row mb-4'>
                <div className='form-group col-sm-12 col-12'>
                  {formik.values.reporter_occupation == 'other' &&
                        <div className="mb-3">
                      <label htmlFor="other_occupation_detail" className={"form-label ".concat(classes.requiredLabel)}>Please specify</label>
                      <textarea className={"form-control ".concat(formik.errors.other_occupation_detail ? classes.invalideFormControl : '')} id="other_occupation_detail" name='other_occupation_detail' rows={3} onChange={formik.handleChange}/>
                      {formik.errors.other_occupation_detail && <div className={classes.errorHelperText}>{formik.errors.other_occupation_detail.toString()}</div>}
                    </div>}
                </div>
              </div>

              <h1>Contact details</h1>
              <div className='row mb-4'>
                <div className='form-group col-sm-12 col-12'>

                  <div>Do you want a response to your feedback?</div>
                  <div className="form-check mt-2">
                    <input className="form-check-input" type="radio" name="need_reseponse" id="need_reseponse_yes" value={'yes'} onChange={onChangeCheckBox}/>
                    <label className="form-check-label" htmlFor="need_reseponse_yes">Yes</label>
                  </div>

                  <div className="form-check mt-2">
                    <input className="form-check-input" type="radio" name="need_reseponse" value={'no'} id="need_reseponse_no" onChange={onChangeCheckBox}/>
                    <label className="form-check-label" htmlFor="need_reseponse_no">No</label>
                  </div>

                </div>
              </div>
              {formik.values.need_reseponse == 'yes' && (<div className='row mb-4'>
                    <div className='form-group col-sm-12 col-12'>
                      <div className="mb-3">
                        <label htmlFor="tele_phone" className="form-label">Telephone</label>
                        <input type='tel' className="form-control" id="tele_phone" name="tele_phone" value={formik.values.tele_phone} onChange={formik.handleChange}/>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input className="form-control" type='email' name="email" id="email" value={formik.values.email} onChange={formik.handleChange}/>
                      </div>
                    </div>
                  </div>)}
            </>}

        <div className='row mb-4'>
          <div className='form-group col-sm-12 col-12'>

            <div className="mb-3">
              <label htmlFor="feedback" className={"form-label ".concat(classes.requiredLabel)}>Feedback</label>
              <textarea className="form-control" id="feedback" rows={3} name="feedback" value={formik.values.feedback} onChange={formik.handleChange}/>
              {formik.errors.feedback && <div className={classes.errorHelperText}>This field is required.</div>}
            </div>

          </div>
        </div>

        <div className='row mb-4'>
          <div className='form-group col-sm-12 col-12'>
            <div className="mb-3">
              <label htmlFor="personal_idea" className="form-label">Please let us know what a good outcome will look like for you or any ideas about how we can improve?</label>
              <textarea className="form-control" id="personal_idea" rows={3} name="personal_idea" value={formik.values.personal_idea} onChange={formik.handleChange}/>
            </div>
          </div>
        </div>


        <div className='row mb-4'>
          <div className='form-group col-sm-12 col-12'>
            <div> Your privacy is important to us. By submitting this form, you consent to Benetas collecting, holding and using the information above in accordance with our Privacy Policy.</div>
          </div>
        </div>

        <div className='d-flex justify-content-center '>
          <button role="button" className="btn btn-sm btn-orange btn-arrow me-3" onClick={function () {
                formik.submitForm();
            }}><span>Submit</span>
          </button>
        </div>
      </>}
    </div>);
};
export default FeedbackForm;
