import React from 'react';
import PricePackages from './price-packages';
import { usePricePackageStep } from '../hooks/PricePackageProvider';
import ServiceFeeStep from './service-fee-step';
import PartnerFeeStep from './partner-fee-step';
import FinalPrice from './final-price';
import PriceRangerStep from './price-ranger-step';
var PricePackageStep = function () {
    var step = usePricePackageStep().step;
    return (<>
      {step == 0 && <PricePackages />}
      {step == 1 && <PriceRangerStep />}
      {step == 2 && <ServiceFeeStep />}
      {step == 3 && <PartnerFeeStep />}
      {step == 4 && <FinalPrice />}
      
      <div className='mt-2'>
        {/* { step > 0 && <button
          role="button"
          className="btn btn-sm btn-orange"
          onClick={()=>{
            setStep(step-1);
          }}><i className="fas fa-angle-left"></i> <span>Back</span></button>} */}

        {/* { step > 0 && <button
          role="button"
          className="btn btn-sm btn-orange"
          onClick={()=>{
            setStep(step-1);
          }}><i className="fas fa-angle-left"></i> <span>Next</span></button>} */}

        {/* { step >= 2 && <button
          role="button"
          className="btn btn-sm btn-orange btn-arrow ms-2"
          onClick={()=>{
            resetStep();
          }}><span>Reset</span></button>
        } */}
      </div>
    </>);
};
export default PricePackageStep;
