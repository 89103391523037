import React from 'react';
import PricePackageStepNav from './price-package-steps-nav';
import PricePackageStep from './price-package-steps';
import { PricePackageStepProvider } from './hooks/PricePackageProvider';
var RootComponent = function () {
    return (<PricePackageStepProvider>
      <div className="container py-5" id="how_much_budget">
        <PricePackageStepNav />
        <PricePackageStep />
      </div>
    </PricePackageStepProvider>);
};
export default RootComponent;
