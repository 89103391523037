import React from 'react';
import { Level } from '../../../../types';
import styles from './style.scss';
var LevelBar = function (_a) {
    var level = _a.level;
    console.log(styles);
    var offset = '25%';
    if (level == Level.LOW) {
        offset = '50%';
    }
    if (level == Level.INTERMEDIATE) {
        offset = '75%';
    }
    if (level == Level.HIGH) {
        offset = '100%';
    }
    return (<div className={styles.root}>
      <div className={styles.process} style={{ width: offset }}>
      </div>
    </div>);
};
export default LevelBar;
