import React from 'react';
import LevelCard from './LevelCard';
import { Level } from '../../types';
var PricePackages = function () {
    var levels = [
        {
            label: 'Level 1',
            level: Level.BASIC,
            service_hours: [7.5, 7.2],
            monthly_cost: 879
        },
        {
            label: 'Level 2',
            level: Level.LOW,
            service_hours: [13.2, 12.6],
            monthly_cost: 1545
        },
        {
            label: 'Level 3',
            level: Level.INTERMEDIATE,
            service_hours: [28.8, 27.5],
            monthly_cost: 3363
        },
        {
            label: 'Level 4',
            level: Level.HIGH,
            service_hours: [43.6, 41.7],
            monthly_cost: 5100
        },
    ];
    return (<div className="row justify-content-center align-items-center g-2">
      <h5 className="mt-4 mb-3">Which level package have you been assigned?</h5>
      {levels.map(function (level, index) {
            return <div className='col-lg-3' key={index}>
            <LevelCard {...level}/>
          </div>;
        })}
    </div>);
};
export default PricePackages;
