import React from 'react';
import { usePricePackageStep } from '../../hooks/PricePackageProvider';
var PartnerFeeStep = function () {
    var _a;
    var _b = usePricePackageStep(), servicePackage = _b.servicePackage, step = _b.step, goNextStep = _b.goNextStep, setStep = _b.setStep;
    return (<div className='mt-3'>
      <h5 className='ff-dax'>You have a total contribution of ${(_a = servicePackage.level) === null || _a === void 0 ? void 0 : _a.monthly_cost.toLocaleString('AU', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}. Now let&apos;s look at fees:</h5>
      <h5 className='ff-dax'>We deduct 15% Package Management fee from your home care package.</h5>
      <div>
        <div className='d-flex gap-2 mt-4 '>
          <button role="button" id="package_mgmt_back" className="btn btn-sm btn-orange" onClick={function () {
            setStep(step - 1);
        }}><i className="fas fa-angle-left"></i> <span>Back</span></button>

          <button role="button" id="package_mgmt_next" className="btn btn-sm btn-orange btn-arrow" onClick={function () {
            goNextStep();
        }}><span>Next</span> <i className="fas fa-angle-right"></i></button>
        </div>
      </div>
    </div>);
};
export default PartnerFeeStep;
