import React from 'react';
import PriceRangeSlider from '../../../custom-slider';
import { usePricePackageStep } from '../../hooks/PricePackageProvider';
var PriceRangerStep = function () {
    var _a;
    var _b = usePricePackageStep(), step = _b.step, servicePackage = _b.servicePackage, setStep = _b.setStep, goNextStep = _b.goNextStep;
    return (<div>
      <h5 className='ff-dax mt-3'>How much is your Income Tested Care Fee?</h5>
      <PriceRangeSlider min={0} max={(_a = servicePackage.level) === null || _a === void 0 ? void 0 : _a.monthly_cost}/>

      <div className='d-flex gap-2 my-5 '>
        <button role="button" id="income_tested_back_button" className="btn btn-sm btn-orange" onClick={function () {
            setStep(step - 1);
        }}><i className="fas fa-angle-left"></i> <span>Back</span></button>

        <button role="button" id="income_tested_next_button" className="btn btn-sm btn-orange btn-arrow" onClick={function () {
            goNextStep();
        }}><span>Next</span> <i className="fas fa-angle-right"></i></button>

        <button role="button" id="income_tested_skip_button" className="btn btn-sm btn-orange btn-arrow" onClick={function () {
            goNextStep();
        }}><span>Skip</span> <i className="fas fa-angle-right"></i></button>

          
      </div>
    </div>);
};
export default PriceRangerStep;
