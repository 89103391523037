import React from 'react';
import { usePricePackageStep } from '../../hooks/PricePackageProvider';
var FinalPrice = function () {
    var _a = usePricePackageStep(), serviceHours = _a.serviceHours, price = _a.price, setStep = _a.setStep, resetStep = _a.resetStep, step = _a.step;
    console.log('servicePackage ==>', serviceHours);
    return (<div className="mt-4">
      <h5 className='ff-dax'> You have ${price.toLocaleString('AU', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} to spend on our services as part of your care plan. </h5>

      <div className='underline-links'>
        <h5 className='ff-dax'>Are you assigned and ready to talk to us? Call us on <a href="tel:1300236382">1300 23 63 82</a></h5>
      </div>

      <div className='d-flex gap-2 mt-5'>
        <button role="button" id="final_price_back_button" className="btn btn-sm btn-orange" onClick={function () {
            setStep(step - 1);
        }}><i className="fas fa-angle-left"></i> <span>Back</span></button>

        <button role="button" id="final_price_reset_button" className="btn btn-sm btn-orange btn-arrow me-3" onClick={function () {
            resetStep();
        }}><span>Reset</span></button>
      </div>


    </div>);
};
export default FinalPrice;
