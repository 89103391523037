import React from 'react';
import styles from './style.scss';
import { usePricePackageStep } from '../../hooks/PricePackageProvider';
var PartnerFeeStep = function () {
    var _a = usePricePackageStep(), price = _a.price, step = _a.step, setStep = _a.setStep, selectServiceFee = _a.selectServiceFee;
    return (<div>
      
      <h5 className='ff-dax mt-3'>Now, with ${price.toLocaleString('AU', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} in your budget, you choose which Benetas Care Management you need:</h5>

      <div className='row g-2 py-4'>
        <div className='col-lg-6'>
          <div className={"card ".concat(styles.card)} id="partnership_selected" onClick={function () {
            selectServiceFee(0.17);
        }}>
            <div className="card-body">
              <h4 className="card-title ff-dax text-teal">Partnership Care Management</h4>
              <p className="card-text ff-dax text-charcoal">Fee: 17% of package level</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className={"card ".concat(styles.card)} id="full_support_selected" onClick={function () {
            selectServiceFee(0.2);
        }}>
            <div className="card-body">
              <h4 className="card-title ff-dax text-teal">Fully Support Care Management</h4>
              <p className="card-text ff-dax text-charcoal">Fee: 20% of package level</p>
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex gap-2 mt-4 '>
        <button role="button" className="btn btn-sm btn-orange" onClick={function () {
            setStep(step - 1);
        }}><i className="fas fa-angle-left"></i> <span>Back</span></button>
      </div>
    </div>);
};
export default PartnerFeeStep;
