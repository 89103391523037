import React from 'react';
import LevelBar from './LevelBar';
import styles from './style.scss';
import { usePricePackageStep } from '../../../hooks/PricePackageProvider';
var LevelCard = function (props) {
    var selectLevel = usePricePackageStep().selectLevel;
    var uniqueId = "".concat(props.label.replace(/[^a-zA-Z0-9]/g, '_'));
    return (<button id={uniqueId} className={"".concat(styles.card)} onClick={function () {
            selectLevel(props);
        }}>
      <div className="text-center pt-2 pb-4 px-3">
        <h5 className="pt-3 text-charcoal ff-dax">{props.label}</h5>
        <p>{props.level}</p>
        <LevelBar level={props.level}/>
      </div>
    </button>);
};
export default LevelCard;
